@import "../../styles/variables.scss";

.dashboard-avatar-menu {
  display: inline-flex;
  align-items: center;

  .menu-container {
    background-color: rgb(133, 196, 248);
    border-radius: 27px;
    height: 40px;
    width: 75px;

    .avatar {
      display: flex;
      height: 30px;
      width: 30px;
    }

    .settings-icon {
      display: flex;
      color: black;
      margin-left: 3px;
    }
  }

  .menu-container:hover {
    background-color: rgb(33, 150, 243);
  }
}

.menu-dropdown {
  padding: 25px;

  .dropdown-header-container {
    .dropdown-header {
      font-size: 16px;
      margin-bottom: 0;
    }

    .dropdown-subheader {
      font-size: 14px;
      font-style: italic;
    }
  }

  .dashboard-avatar-list {
    .dashboard-avatar-list-item {
      border: none;
      background-color: transparent;
      color: black;
    }

    .dashboard-avatar-list-item:hover {
      background-color: var(--neutral-300);
      color: black;
      text-decoration: none;
      border-radius: 5px;
    }
  }
}
