@import "../../styles/variables.scss";

.artists {
  overflow-x: hidden;

  .artist-description {
    font-size: 18px;

    @media (max-width: $sm-max) {
      font-size: 16px;
      padding: 0px 14px;
    }
  }

  .artists-slider {
    height: 400px;

    .slider-container {
      .artists-slide {
        .artist {
          transition: all 0.2s ease-in-out;
          padding: 25px 0px;

          .artist-img {
            width: 200px;
            height: 200px;
            margin: 0 auto;

            @media (max-width: $sm-max) {
              width: 125px;
              height: 125px;
            }
          }

          .artist-name {
            margin-top: 20px;
            font-size: 18px;
            font-weight: $semibold;
            color: black;
            text-decoration: none;

            @media (max-width: $sm-max) {
              font-size: 16px;
            }
          }
        }

        .artist:hover {
          transform: scale(1.2);
        }
      }
    }

    @media (max-width: $sm-max) {
      height: 300px;
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
