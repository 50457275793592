@import "../../styles/variables.scss";

.spotify-follower-count-card {
  .MuiPaper-root {
    background-image: linear-gradient(to top left, white 70%, #76b48c);
  }

  .card-content {
    padding-bottom: 17px !important;

    .content-container {
      text-align: end;

      .fa-spotify {
        color: #1db954 !important;
        font-size: 45px;
      }

      h2 {
        margin-bottom: 0;
      }

      .card-description {
        font-size: 12px;
        font-weight: $bold;
        margin-bottom: 0px;
      }
    }
  }
}
