@import "../../../styles/variables.scss";

.account-settings {
  .email-note {
    margin-top: 5px;
    font-size: 12px;
    font-style: italic;
    color: var(--neutral-500);
  }
}
