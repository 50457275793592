@import "../../styles/variables.scss";

.blog-post-card {
}

@media (max-width: $sm-max) {
  .blog-post-card {
    .blog-post-card-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
