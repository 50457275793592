@import "../../styles/variables.scss";

.license-modal {
  .modal-content {
    width: 850px;
    border: 1px solid white;
  }

  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }

  .license-modal-bg {
    background-color: black;
    color: lightgray;
  }

  .license-modal-bg > button {
    color: lightgray;
  }

  .license-modal-notice {
    color: var(--error-500);
    text-align: center;
  }
}

@media (max-width: $sm-max) {
  .license-modal {
    margin-top: 100px !important;

    .modal-content {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-size: 12px;
    }

    .modal-body {
      max-height: 450px;
    }
  }
}
