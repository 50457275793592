@import "../../styles/variables.scss";

.pricing {
  .pricing-background {
    background-image: url("../../images/backgrounds/board.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .pricing-description {
    font-size: 18px;

    .bulk-deals-subtext {
      font-size: 12px;
    }
  }

  .pricing-container {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-pagination-bullet {
    background: white;
    opacity: 0.8;
  }

  .swiper-pagination-bullet-active {
    background: var(--plate-gold-500);
    opacity: 1;
  }
}
