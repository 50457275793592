@import "../../styles/variables.scss";

.homepage-navbar {
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;

  .logo-link {
    text-decoration: none;
  }

  .navbar-logo {
    margin: 15px 15px 15px 40px;
    width: 40px;
  }

  .logo-text {
    position: absolute;
    top: 18px;
    color: white;
  }

  .nav-link-container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 40px;

    .nav-link {
      font-size: 18px;
      margin: 0px 10px;
      color: white;
      text-decoration: none;
    }

    .nav-link:hover {
      color: var(--plate-gold-500);
    }

    .nav-social-links-container {
      margin: 4px 10px auto 5px;

      .nav-social-link {
        margin: 0px 10px;
      }

      .social-icon {
        color: white;
      }

      .fa-soundcloud:hover {
        color: #ff8800 !important;
      }

      .fa-instagram:hover {
        color: #833ab4 !important;
      }

      .fa-facebook-square:hover {
        color: #3b5998 !important;
      }

      .fa-twitter:hover {
        color: #00acee !important;
      }

      .fa-youtube:hover {
        color: #c4302b !important;
      }

      .fa-envelope:hover {
        color: #f1d592 !important;
      }

      .fa-twitch:hover {
        color: #6441a5 !important;
      }

      .fa-music:hover {
        color: #cc0fe0 !important;
      }

      .fa-spotify:hover {
        color: #1db954 !important;
      }

      .fa-tiktok:hover {
        color: #69c9d0 !important;
      }

      .fa-apple:hover {
        color: #fc3c44 !important;
      }
    }
  }

  .nav-drawer-toggle {
    display: none;
    margin-left: auto;
    margin-right: 25px;
  }

  .nav-drawer-toggle-button {
    color: white;
  }

  .nav-drawer-toggle-button:hover {
    background-color: none;
  }

  @media (max-width: $md-max) {
    .nav-link-container,
    .navbar-logo {
      display: none;
    }

    .nav-drawer-toggle {
      display: flex;
      margin-left: auto;
      margin-right: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .logo-text {
      margin-left: 25px;
      position: relative;
      top: 15px;
    }
  }
}

.homepage-navbar.solid-nav {
  background-color: white !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;

  .logo-text {
    position: absolute;
    top: 18px;
    color: black;
  }

  .nav-link {
    color: black;
  }

  .nav-social-links-container {
    .social-icon {
      color: var(--neutral-500);
    }
  }

  .nav-drawer-toggle {
    margin-left: auto;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-drawer-toggle-button {
    color: black;
  }

  @media (max-width: $md-max) {
    .nav-link-container,
    .navbar-logo {
      display: none;
    }

    .nav-drawer-toggle {
      display: flex;
    }

    .logo-text {
      position: relative;
      top: 15px;
    }
  }
}
