:root {
  /**********************************************************
    BEATS BY ZER0 DESIGN SYSTEM
  **********************************************************/

  // Plate Gold
  --plate-gold-100: #fdf8de;
  --plate-gold-200: #fbefbf;
  --plate-gold-300: #f4e09c;
  --plate-gold-400: #e9ce80;
  --plate-gold-500: #dbb658;
  --plate-gold-600: #bc9540;
  --plate-gold-700: #9d772c;
  --plate-gold-800: #7f5a1c;
  --plate-gold-900: #694510;

  // Matte Gold
  --matte-gold-100: #fcf7e9;
  --matte-gold-200: #f9edd5;
  --matte-gold-300: #eddaba;
  --matte-gold-400: #dcc3a0;
  --matte-gold-500: #c5a47e;
  --matte-gold-600: #a9815c;
  --matte-gold-700: #8d623f;
  --matte-gold-800: #724528;
  --matte-gold-900: #5e3018;

  // Gradients
  --exclusive-gradient: linear-gradient(to bottom right, black, #b32e2e);
  --premium-gradient: linear-gradient(to bottom right, black, #450a3b);

  /**********************************************************
  POLIS TECH GENERAL DESIGN SYSTEM
  **********************************************************/

  // System - Neutral (Default, Deleted, Coming Soon, Base, etc)
  --neutral-100: #f3f4f5;
  --neutral-200: #e7eaec;
  --neutral-300: #c1c5c7;
  --neutral-400: #8a8d90;
  --neutral-500: #434547;
  --neutral-600: #30363d;
  --neutral-700: #212833;
  --neutral-800: #151c29;
  --neutral-900: #0c1322;

  // System - Positive (Success, Complete, Added, Purchased, etc)
  --success-100: #e0f8d3;
  --success-200: #bcf1a9;
  --success-300: #87d776;
  --success-400: #55af4d;
  --success-500: #1f7a1f;
  --success-600: #16681d;
  --success-700: #0f571c;
  --success-800: #094619;
  --success-900: #053a18;

  // System - Caution (Pending, Warning, Scheduled, Advisory, etc)
  --warning-100: #fdf3d8;
  --warning-200: #fce4b2;
  --warning-300: #f8cf8b;
  --warning-400: #f2ba6c;
  --warning-500: #ea9a3e;
  --warning-600: #c9792d;
  --warning-700: #a85b1f;
  --warning-800: #874113;
  --warning-900: #702e0b;

  // System - Negative (Errors, Alerts, Failed, Rejected, etc)
  --error-100: #fddfcf;
  --error-200: #fcb8a1;
  --error-300: #f68871;
  --error-400: #ee5a4c;
  --error-500: #e41616;
  --error-600: #c4101f;
  --error-700: #a40b25;
  --error-800: #840727;
  --error-900: #6d0428;
}
