@import "../../styles/variables.scss";

.users-dashboard-card {
  .content-container {
    .card-header {
      margin: 15px 5px;
    }

    .card-table-container {
      min-height: 400px;
      max-height: 400px;
      overflow-y: auto;

      .cell-container {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
