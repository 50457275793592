@import "../../styles/variables.scss";

.blog {
  background-color: var(--neutral-300) !important;
  margin-top: 50px;
  min-height: 100vh;
}

@media (max-width: $sm-max) {
  .blog {
    .title-area {
      margin-bottom: 50px !important;
    }
  }
}
