@import "../../styles/variables.scss";

.dashboard-sub-page-toolbar {
  .progressbar-container {
    display: flex;
    flex-direction: row;
    width: 500px;
    margin-right: 50px;

    p {
      font-weight: $semibold;
      color: black;
    }

    .progressbar-title {
      margin: auto 0px auto 20px;
      font-weight: $bold;
    }

    .dashboard-linear-progressbar {
      border-radius: 15px;
      height: 10px;
    }

    .progressbar-good-status {
      background-color: var(--success-200);

      span {
        background-color: var(--success-500);
      }
    }

    .progressbar-warning {
      background-color: var(--warning-200);

      span {
        background-color: var(--warning-500);
      }
    }

    .progressbar-full {
      background-color: var(--error-200);

      span {
        background-color: var(--error-500);
      }
    }
  }

  .toolbar-container {
    display: flex;
    flex-direction: row;
    padding: 15px 15px 15px 0px;

    .toolbar-title {
      margin: auto 0px auto 20px;
    }
  }
}
