@import "../../styles/variables.scss";

.title-header {
  margin-bottom: 75px;
}

.contact-form {
  padding: 0px 0px 0px 200px;

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    background-color: black;
    border: 1px solid white;
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
  }

  .form-control::placeholder {
    color: white;
  }

  .form-control:focus {
    background-color: black;
    border: 1px solid var(--plate-gold-500);
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
  }

  .submit-text {
    margin-right: 5px;
  }

  .btn-contact-submit {
    font-weight: 600;
  }
}

@media (max-width: $md-max) {
  .title-header {
    margin-bottom: 0px;
  }

  .contact-form-header {
    margin: 35px 0px;
  }
  .contact-form {
    margin-top: 25px;
    padding: 0px 0px 0px 100px;
  }
}

@media (max-width: $sm-max) {
  .contact-form-header {
    margin: 35px 0px;
    padding: 0px 20px;
  }
  .contact-form {
    margin-top: 25px;
    padding: 0px 20px;
  }
}
