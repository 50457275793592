@import "../../styles/variables.scss";

.beat-store-description {
  font-size: 18px;
}

.btn-deal {
  background-color: #000 !important;
  border-color: #fff;
  color: #fff;
  margin-top: 15px;
  text-align: center;
}

.btn-deal:hover {
  background-color: #fff !important;
  border-color: var(--plate-gold-500) !important;
  color: black !important;
  margin-top: 15px;
  text-align: center;
}

.download-arrow {
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.marketplace-discount {
  align-content: center;
  text-align: center;
}

.playerBorder {
  border: black solid 1px;
}

@media (max-width: $sm-max) {
  .beat-store-description {
    font-size: 16px;
    padding: 0px 14px;
  }

  .marketplace-iframe {
    padding: 0px 20px;
  }
}
