@import "../../styles/variables.scss";

.sound-kits-dashboard-card {
  .content-container {
    .card-header {
      margin: 15px 5px;
    }

    .sound-kits-table-card-container {
      min-height: 400px;
      max-height: 400px;
      overflow-y: auto;
    }
  }
}
