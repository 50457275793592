@import "../../../styles/variables.scss";

.artists-page {
  .artists-table-container {
    padding: 0px;

    .artists-table {
      border: 2px solid var(--neutral-300);
    }
  }
}
