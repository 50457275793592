$directions: top bottom right left;
$values: 0 3 4 5 8 10 12 15 16 18 20 21 24 25 28 30 32 35 36 38 40 42 45 48 50
  56 64 70 75 80 90 100;

@each $direction in $directions {
  @each $value in $values {
    .margin-#{$direction}-#{$value} {
      margin-#{$direction}: #{$value}px !important;
    }
    .padding-#{$direction}-#{$value} {
      padding-#{$direction}: #{$value}px !important;
    }
  }
}

@each $value in $values {
  .gap-#{$value} {
    gap: #{$value}px !important;
  }
  .column-gap-#{$value} {
    column-gap: #{$value}px !important;
  }
  .row-gap-#{$value} {
    row-gap: #{$value}px !important;
  }
  .margin-#{$value} {
    margin: #{$value}px !important;
  }
  .padding-#{$value} {
    padding: #{$value}px !important;
  }
  // flex gap pollyfill since Safari doesn't support gap in flex layouts until v.14.1
  .flex-gap-#{$value} {
    display: flex;
    margin: -#{calc($value / 2)}px;
    > * {
      margin: #{calc($value / 2)}px;
    }
  }
}

.jc-center {
  justify-content: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.v-middle {
  vertical-align: middle;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-horizonal-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-vertical-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
