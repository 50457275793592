@import "../../styles/variables.scss";

.pricing-card {
  background-color: black;
  border-radius: 10px;
  width: 275px;
  text-align: center;
  overflow: hidden;
  position: relative;

  .pricing-card-header {
    display: flex;
    flex-direction: column;

    .pricing-card-title {
      text-transform: uppercase;
      font-weight: 400;
      margin: 25px 0px 5px 0px;
      display: block;
      font-size: 25px;
    }

    .heading {
      display: block;
      padding-top: 30px;
      position: relative;
    }
  }

  .pricing-card-price {
    display: block;
    color: white;
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  .negotiable {
    font-size: 28px;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .pricing-card-details {
    margin-top: 15px;

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
    }

    li {
      color: white;
      padding: 5px 35px;
    }
  }

  .pricing-card-modal-container {
    padding: 30px 15px;

    .btn-lease {
      color: white;
      border: 1px solid white;
      padding: 5px 25px;
      background-color: black;
    }

    .btn-lease:hover {
      color: black;
      border-color: white;
      background-color: white;
    }
  }

  .ribbon {
    width: 160px;
    height: 30px;
    font-size: 12px;
    text-align: center;
    color: black;
    font-weight: bold;
    box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
    background: var(--plate-gold-500);
    transform: rotate(45deg);
    position: absolute;
    right: -45px;
    top: 20px;
    padding-top: 7px;
  }
}

.exclusive-card {
  background-image: var(--exclusive-gradient);
}

.premium-card {
  background-image: var(--premium-gradient);
}
