@import "../../styles/variables.scss";

.products-count-card {
  .MuiPaper-root {
    background-image: linear-gradient(
      to top left,
      white 70%,
      rgb(173, 195, 236)
    );
  }

  .card-content {
    padding-bottom: 17px !important;

    .content-container {
      text-align: end;

      h2 {
        margin-bottom: 0;
      }

      .card-description {
        font-size: 12px;
        font-weight: $bold;
        margin-bottom: 0px;
      }
    }
  }
}
