@import "../../styles/variables.scss";

.project-card {
  transition: 0.75s;

  a {
    color: white;
    text-decoration: none;
  }

  .card-container {
    min-height: 300px;
    width: auto;

    .card-overlay {
      background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0) 200px
        ),
        linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 300px);
    }
  }
}

.project-card:hover {
  scale: 1.1;

  .card-overlay {
    background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0) 200px
      ),
      linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 50px);
  }
}

@media (max-width: $lg-min) {
  .project-card {
    margin-bottom: 20px;

    .card-container {
      min-height: 200px;
    }
  }
}

@media (max-width: $sm-max) {
  .project-card {
    margin-bottom: 30px;

    .card-container {
      min-height: 150px;
    }
  }
}
