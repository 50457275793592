@import "../../../styles/variables.scss";

.edit-user-modal {
  margin-top: 100px !important;
  width: 500px;

  .form-control {
    background-color: white;
    color: black;
  }

  .form-control:focus {
    background-color: white;
    color: black;
  }
}
