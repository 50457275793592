@import "../../../../styles/variables.scss";

.change-password-modal {
  margin-top: 150px;
  width: 500px;

  .form-control {
    background-color: white;
    color: black;
  }

  .form-control:focus {
    background-color: white;
    color: black;
  }
}
