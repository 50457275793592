@import "../../styles/variables.scss";

.site-footer {
    color: white;
    padding: 25px 600px;
}

    .site-footer > hr {
        background-color: #444;
    }

.footer-link {
    margin: 0px 15px;
}

@media only screen and (max-width: 992px) {
    .site-footer {
        color: white;
        padding: 25px 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .site-footer {
        color: white;
        padding: 25px 300px;
    }
}