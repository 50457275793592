@import "../../styles/variables.scss";

.confirm-cancel-modal {
  margin-top: 150px;
  width: 500px;
}

@media (max-width: $sm-max) {
  .confirm-cancel-modal {
    width: 90%;
  }
}
