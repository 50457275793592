@import "../../../styles/variables.scss";

.add-user-modal {
  margin-top: 100px !important;
  width: 500px;

  .form-control {
    background-color: white;
    color: black;
  }

  .form-control:focus {
    background-color: white;
    color: black;
  }
}

@media (max-width: $sm-max) {
  .add-user-modal {
    width: 90%;
  }
}
