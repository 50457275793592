@import "../../styles/variables.scss";

.view-blog-post {
  .masthead {
    position: relative;
    margin-bottom: 3rem;
    padding-top: calc(8rem + 57px);
    padding-bottom: 8rem;
    background: no-repeat center center;
    background-color: #6c757d;
    background-size: cover;
    background-attachment: scroll;
  }

  .masthead:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.5;
  }

  .post-heading {
    color: white;
  }

  header.masthead .post-heading .subheading {
    font-size: 1.5rem;
    font-weight: $semibold;
    margin: 0.75rem 0 2rem;
  }

  header.masthead .post-heading .meta {
    font-size: 1.25rem;
    font-style: italic;
  }

  .date {
    top: 1px;
    margin-left: 10px;
    position: relative;
    font-size: 18px;
  }

  .article-container {
    margin-top: 75px;

    p {
      font-size: 1.25rem;
      margin: 25px 0px;
    }
  }

  @media (min-width: $md-min) {
    .masthead {
      padding-top: 12.5rem;
      padding-bottom: 12.5rem;
    }
  }
}
