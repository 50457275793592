@import "../../styles/variables.scss";

.dashboard-mobile-drawer-account-avatar {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-bottom: 10px;

  .user-info-container {
    display: flex;
    flex-direction: column;
    font-weight: $bold;

    .position {
      color: var(--plate-gold-500);
    }
  }

  .avatar-container {
    margin-left: auto;
    .avatar {
      border: 2px solid white;
    }
  }
}
