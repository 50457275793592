@import "../../styles/variables.scss";

.youtube-title-area {
    max-width: 760px;
    margin: 50px auto 100px;
    display: block;
    padding: 0 15px;
    text-align: center;
}

.youtube-background {
    background-image: url('../../images/backgrounds/microphone.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.section.youtube-background {
    padding-bottom: 175px;
}

.youtube-content {
    padding-left: 100px;
    padding-right: 100px;

    .odometer {
        font-size: 50px;
        padding-left: 10px;
    }

    .odometer-text {
        font-size: 20px;
        padding-left: 15px;
    }

    .youtube-stats {
        padding: 7px 0px;
    }
}

.youtube-stats-section {
    .btn-youtube {
        background-color: #D32F2F !important;
        border-color: white;
        color: white;
        margin-top: 12px;
    }

    .btn-youtube:hover {
        background-color: #D32F2F !important;
        border-color: white;
        color: white;
    }
}

.responsive-iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: $md-max) {
    .youtube-content {
        .odometer {
            font-size: 40px;
            padding-left: 50px;
        }

        .odometer-text {
            font-size: 20px;
            padding-left: 50px;
        }

        .youtube-stats {
            padding: 7px 0px;
        }
    }
}


@media (max-width: $sm-max) {
    .youtube-title-area {
        max-width: 760px;
        margin: 50px auto 50px;
        display: block;
        padding: 0 15px;
        text-align: center;
    }

    .youtube-section {
        text-align: center;
    }

    .youtube-content {
        padding-left: 0px;
        padding-right: 0px;

        .youtube-stats {
            padding: 7px 0px;
        }
    }

    .responsive-iframe {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: $xs-max) {
    .youtube-content {
        text-align: center;

        .odometer {
            font-size: 50px;
            padding-left: 0px;
        }

        .odometer-text {
            font-size: 20px;
            padding-left: 0px;
        }

        .youtube-stats {
            padding: 7px 0px;
        }
    }

    .responsive-iframe {
        margin-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        height: 125%;
    }
}