@import "../../styles/variables.scss";

.mobile-nav-drawer {
  .MuiDrawer-paper {
    background-color: black;
  }

  .mobile-nav-link-container {
    padding: 15px 0px;

    .nav-link {
      color: white;
      text-decoration: none;
      margin: 25px 25px 25px 35px;
      padding: 0px 75px 0px 0px;
      font-size: 20px;
    }

    .nav-link:hover {
      color: var(--plate-gold-500);
    }

    .social-divider {
      color: white;
      margin: 30px 25px;
      border: 1.5px solid white;
      opacity: 1;
    }

    .mobile-nav-social-links-container {
      padding: 0px 25px;
      flex-wrap: wrap;

      .nav-social-link {
        margin: 10px 7px 12px 0px;
        text-align: center;
        flex: 1 0 15%;
      }

      .social-icon {
        color: white;
      }

      .fa-soundcloud:hover {
        color: #ff8800 !important;
      }

      .fa-instagram:hover {
        color: #833ab4 !important;
      }

      .fa-facebook-square:hover {
        color: #3b5998 !important;
      }

      .fa-twitter:hover {
        color: #00acee !important;
      }

      .fa-youtube:hover {
        color: #c4302b !important;
      }

      .fa-envelope:hover {
        color: #f1d592 !important;
      }

      .fa-twitch:hover {
        color: #6441a5 !important;
      }

      .fa-music:hover {
        color: #cc0fe0 !important;
      }

      .fa-spotify:hover {
        color: #1db954 !important;
      }

      .fa-tiktok:hover {
        color: #69c9d0 !important;
      }

      .fa-apple:hover {
        color: #fc3c44 !important;
      }
    }
  }
}
