$basis-values: 25 33 50 66 75 99 100;

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-width-0 {
  min-width: 0;
  width: 100%;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-0 {
  flex-grow: 0;
}

.flex-1 {
  flex-grow: 1;
}

.flex-2 {
  flex-grow: 2;
}

.flex-3 {
  flex-grow: 3;
}

.flex-4 {
  flex-grow: 4;
}

.flex-5 {
  flex-grow: 5;
}

@each $value in $basis-values {
  .flex-basis-#{$value} {
    flex-basis: #{$value + '%'} !important
  }
}

