.animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-5px);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

.ink-bg {
    height: 100%;
    width: 100%;
    transform: scale(1.25);
}

.title-screen-logo {
    width: 50%;
    height: auto;
}

.title-arrow {
    margin-top: 20px;
}
