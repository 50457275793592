@import "../../../styles/variables.scss";

.dashboard-layout {
  background-color: white;

  .dashboard-main-container {
    background-color: var(--neutral-200);
    min-height: calc(100vh - 88px);
    flex-grow: 1;
    padding: 20px;
    margin: 75px 10px 0px 10px;
    border-radius: 8px 8px 0px 0px;

    .dashboard-button {
      font-size: 14px;
      font-weight: $semibold;
    }

    .dashboard-snackbar {
      margin-top: 50px;

      .MuiAlert-standard {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: $semibold;
      }
    }

    .dashboard-table-container {
      padding: 30px;

      .dashboard-table {
        border: 2px solid var(--neutral-300);
      }
    }
  }
}
