@import '../styles/variables.scss';

.break-words {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.strong,
.text-bold {
  font-weight: $bold;
}

.text-semibold {
  font-weight: $semibold;
}

.text-light {
  font-weight: $light;
}

.text-lighter {
  font-weight: lighter !important;
}

.text-normal {
  font-weight: $regular;
}

.text-italic {
  font-style: italic;
}

.text-no-transform {
  text-transform: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

$font-sizes: 6 8 9 10 11 12 13 14 15 16 17 18 20 21 22 24 25 26 28 30 32 42 52 58;

@each $font-size in $font-sizes {
  .fs-#{$font-size} {
    font-size: $font-size * 1px !important;
  }

  .lh-#{$font-size} {
    line-height: $font-size * 1px !important;
  }
}