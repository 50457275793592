@import "../../styles/variables.scss";

.projects {
  background-color: var(--neutral-300) !important;
  margin-top: 50px;
  min-height: 100vh;

  .projects-container {
    .project-group-header {
      font-weight: $semibold;
      padding-bottom: 10px;
      border-bottom: 1px solid black;
    }
  }
}

@media (max-width: $sm-max) {
  .projects {
    .title-area {
      margin-bottom: 50px !important;
    }
  }
}
