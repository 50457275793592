@import "../../styles/variables.scss";

.dashboard-navbar {
  background: black !important;
  color: white !important;
  margin-left: 0 !important;
  width: 100% !important;

  .navbar-link {
    color: white;
  }

  .navbar-link:hover {
    color: var(--matte-gold-500);
  }

  .mobile-drawer-toggle {
    display: none;
  }
}

.side-nav {
  .MuiDrawer-paper {
    color: white;
    background-color: black;
  }

  .side-nav-divider {
    border-color: white;
  }

  .open-side-nav-list-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .dashboard-nav-sub-header {
    font-size: 16px;
    font-weight: $semibold;
    color: white;
    background-color: black;
  }

  .dashboard-nav-list-item {
    color: white;

    .dashboard-nav-icon {
      padding-left: 6px;
      color: white;
    }
  }

  .dashboard-nav-list-item:hover {
    background-color: var(--neutral-800);
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
}

@media (max-width: $sm-max) {
  .dashboard-navbar {
    .desktop-side-nav-toggle {
      display: none;
    }

    .profile-tools-container {
      display: none;
    }

    .mobile-drawer-toggle {
      display: block;
      margin-left: auto;

      button {
        color: white;
      }
    }
  }
}

@media (max-width: $sm-max) {
  .side-nav {
    display: none;
  }
}
