@import "../../styles/variables.scss";

.user-count-card {
  .MuiPaper-root {
    background-image: linear-gradient(
      to bottom right,
      rgb(30, 136, 229),
      rgb(144, 202, 249)
    );
  }

  .content-container {
    padding: 25px;

    .icon-container {
      text-align: end;
    }

    .data-container {
      color: white;
      margin: 5px 0px;

      .card-description {
        line-height: 1.334em;
        color: rgb(185, 222, 252);
        font-weight: $bold;
      }
    }
  }
}
