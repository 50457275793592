// Font Weights
$extrabold: 800;
$bold: 700;
$semibold: 600;
$regular: 400; // normal
$light: 300;

// Breakpoints
$lg-min: 1200px;
$md-max: $lg-min - 1;
$md-min: 992px;
$sm-max: $md-min - 1;
$sm-min: 768px;
$xs-max: $sm-min - 1;
$xs-min: 480px;

// z-indexs
$z-index-max-background: -9999;
$z-index-max-foreground: 9999;

// Site Colors
$bbz-premium-gradient: linear-gradient(to bottom right, black, #450a3b);
$bbz-exclusive-gradient: linear-gradient(to bottom right, black, #b32e2e);
