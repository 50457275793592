@import "../../styles/variables.scss";

.dashboard-mobile-drawer {
  .MuiDrawer-paper {
    background-color: black;
  }

  .dashboard-nav-sub-header {
    font-size: 16px;
    font-weight: $semibold;
    color: white;
    background-color: black;
  }

  .dashboard-nav-list-item {
    color: white;

    .dashboard-nav-icon {
      padding-left: 6px;
      color: white;
    }
  }

  .dashboard-nav-list-item:hover {
    background-color: var(--neutral-800);
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }

  .divider {
    border-color: white;
    opacity: 0.8;
  }
}
