@import "../../styles/variables.scss";

.faqs {
  background-color: var(--neutral-300) !important;
  margin-top: 50px;

  .faq-group {
    .faq-item {
      margin-bottom: 25px;

      .faq-answer-container {
        background-color: black;
        color: white;

        .faq-answer {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: $sm-max) {
  .faqs {
    .title-area {
      margin-bottom: 50px !important;
    }
  }
}
