@import "../../styles/variables.scss";

.blog-dashboard-card {
  .content-container {
    .card-header {
      margin: 15px 5px 25px;
    }

    .card-body-container {
      min-height: 390px;
      max-height: 390px;
      overflow-y: auto;

      .swiper-slide {
        width: auto;
      }

      .swiper-pagination-bullet {
        background: white;
        opacity: 0.8;
      }

      .swiper-pagination-bullet-active {
        background: var(--plate-gold-500);
        opacity: 1;
      }
    }
  }
}
